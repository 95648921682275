import { render, staticRenderFns } from "./BoasVindas.vue?vue&type=template&id=a53d6ff4&scoped=true"
import script from "./BoasVindas.vue?vue&type=script&lang=js"
export * from "./BoasVindas.vue?vue&type=script&lang=js"
import style0 from "./BoasVindas.vue?vue&type=style&index=0&id=a53d6ff4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a53d6ff4",
  null
  
)

export default component.exports